import { AssetType, ExtendedCar } from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import SearchFilterPanel from '@smart/components-adb/atoms/SearchFilterPanel/SearchFilterPanel';
import CreateDocumentType from '@smart/components-adb/documents/CreateDocumentType/CreateDocumentType';
import FilterDropdown from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, IconButton, Tag, TextInput } from '@smart/react-components';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { useAgentContext } from 'contexts/agent-context';
import { useMarketContext } from 'contexts/market-context';
import { useAllAppointmentsQuery } from 'graphql/queries/appointments.generated';
import { enhanceError } from 'graphql/reactive-error';
import { filterNonServiceAppointments } from 'pages/tasks/config';
import { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentsContext } from '../documents-context';
import { UploadType } from '../Documents.config';
import { usePurchasedProductsQuery } from '../queries.generated';
import { DocumentFilters, FilterTypeValue } from './Search.config';
import './Search.scss';

const BASE_CLASS = 'adb-document-search';

const mapFilters = (
  query: FilterTypeValue[] | undefined,
  type: DocumentFilters
) =>
  (query &&
    [...query].find(
      (item) =>
        item.type === type && {
          displayName: item.displayName.toLowerCase(),
          queryValue: item.queryValue.toLowerCase(),
          type: item.type,
        }
    )) ??
  undefined;

const UploadDocument = lazy(() => import('../UploadDocument/UploadDocument'));

const DocumentsSearch = () => {
  const { t } = useTranslation();
  const { agent } = useAgentContext();
  const { market } = useMarketContext();
  const { registerModal } = useModal();

  const {
    documentsLoading,
    documentsSearchQuery,
    documentsFilterQuery,
    documentsSearchQuerySet,
    documentsFilterSet,
    documentsRefresh,
    documentsBulkDownload,
    documentsBulkDownloadLoading,
    filters: { category, status, type },
    customer,
    customerLoading,
    uploadType,
    source,
  } = useDocumentsContext();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const categoryFilter = mapFilters(
    documentsFilterQuery,
    DocumentFilters.CATEGORY
  );

  const useNewHandoverDocument = useFeatureFlag({
    defaultValue: false,
    key: 'use-new-handover-document',
  });

  const statusFilter = mapFilters(documentsFilterQuery, DocumentFilters.STATUS);
  const typeFilter = mapFilters(documentsFilterQuery, DocumentFilters.TYPE);

  const createEnabled =
    uploadType !== UploadType.Outlet || useNewHandoverDocument;

  const { data: getCars, loading: purchasedProductsLoading } =
    usePurchasedProductsQuery({
      variables: {
        input: {
          uuid: customer?.uuid ?? '',
          type: AssetType.SellableVehicle,
        },
      },
      onError: (error) => {
        enhanceError({
          error,
          label: 'Failed to fetch customer products',
          displayMessage: error.message,
        });
      },
      skip: !customer?.uuid,
    });

  const { data: appointments, loading: isLoadingAppointments } =
    useAllAppointmentsQuery({
      variables: {
        input: {
          outletId: agent?.outletId ?? '',
          startDateTime: getDateXMonthsBefore(2).toISOString(),
          endDateTime: getDateXMonthsAfter(2).toISOString(),
          customerId: customer?.uuid,
        },
      },
      skip: !customer || !agent?.outletId,
    });

  const purchasedProducts = (getCars?.getCars.products ?? []) as ExtendedCar[];

  const customerAppointments = filterNonServiceAppointments(
    appointments?.allAppointments.appointments
  );

  const toggleCreateDocumentOpen = (): void => {
    if (!customer || !createEnabled || !source) return;
    registerModal(
      <CreateDocumentType
        docSystem={source}
        uploadType={uploadType}
        selectedCustomerById={customer}
        appointments={customerAppointments}
        onCompleted={documentsRefresh}
      />
    );
  };

  const toggleUploadDocumentOpen = (): void => {
    if (!customer || !source) return;
    registerModal(
      <Suspense fallback={<LoadingIndicator />}>
        <UploadDocument
          docSystem={source}
          selectedCustomer={customer}
          selectedOutletMarket={market}
          purchasedProducts={purchasedProducts}
          onUploadedDocument={documentsRefresh}
          type={uploadType}
        />
      </Suspense>
    );
  };

  return (
    <>
      <SearchFilterPanel
        searchBar={
          <TextInput
            id="table_search"
            value={documentsSearchQuery}
            onChange={(event) => documentsSearchQuerySet(event.target.value)}
            label={t('customer.documents.search')}
            type="text"
          />
        }
        actionBtns={
          <>
            {customer && createEnabled && (
              <Button
                variant="secondary"
                mode={200}
                type="button"
                onClick={toggleCreateDocumentOpen}
                className={`${BASE_CLASS}__btn-text`}
                disabled={isLoadingAppointments}
                loading={isLoadingAppointments}
              >
                <Button.Spinner />
                {t('customer.documents.buttons.create_document')}
              </Button>
            )}

            <Button
              variant="secondary"
              mode={200}
              type="button"
              onClick={documentsBulkDownload}
              className={`${BASE_CLASS}__btn-text`}
              disabled={
                documentsBulkDownloadLoading ||
                customerLoading ||
                documentsLoading
              }
              loading={documentsBulkDownloadLoading}
            >
              <Button.Spinner />
              {t('customer.documents.buttons.download')}
            </Button>

            {customer && (
              <Button
                variant="secondary"
                mode={200}
                type="button"
                onClick={toggleUploadDocumentOpen}
                className={`${BASE_CLASS}__btn-text`}
                disabled={purchasedProductsLoading || customerLoading}
                loading={purchasedProductsLoading}
              >
                <Button.Spinner />
                {t('customer.documents.buttons.upload_new_document')}
              </Button>
            )}

            <Button
              variant="ghost"
              mode={200}
              type="button"
              onClick={documentsRefresh}
              className={`${BASE_CLASS}__btn-text`}
              disabled={documentsLoading}
            >
              <Button.Icon icon="refresh" />
              {t('customer.documents.buttons.refresh')}
            </Button>

            {customer && createEnabled && (
              <IconButton
                variant="secondary"
                mode={200}
                type="button"
                onClick={toggleCreateDocumentOpen}
                className={`${BASE_CLASS}__btn-icon`}
                aria-label="create document"
              >
                <Button.Icon icon="plus" aria-label="plus" />
              </IconButton>
            )}

            {customer && (
              <IconButton
                variant="secondary"
                mode={200}
                aria-label="upload document"
                type="button"
                onClick={toggleUploadDocumentOpen}
                className={`${BASE_CLASS}__btn-icon`}
              >
                <Button.Icon icon="upload" aria-label="upload" />
              </IconButton>
            )}

            <IconButton
              variant="secondary"
              mode={200}
              aria-label="refresh document list"
              type="button"
              onClick={documentsRefresh}
              className={`${BASE_CLASS}__btn-icon`}
              disabled={documentsLoading}
            >
              <Button.Icon icon="refresh" aria-label="refresh" />
            </IconButton>
          </>
        }
        filters={
          <Button
            variant="ghost"
            mode={200}
            type="button"
            onClick={() => setShowFilters((prev) => !prev)}
          >
            {showFilters
              ? t('customer.documents.buttons.hide_filter')
              : t('customer.documents.buttons.show_filter')}
            <Button.Icon icon="filter" />
          </Button>
        }
      />
      {showFilters && (
        <div className={BASE_CLASS}>
          <div className={`${BASE_CLASS}__filters`}>
            <FilterDropdown
              label={t('customer.documents.facets.category')}
              items={category}
              onSelect={(filter) =>
                documentsFilterSet(filter, DocumentFilters.CATEGORY)
              }
              selectedFilter={categoryFilter}
            />

            <FilterDropdown
              label={t('customer.documents.facets.status')}
              items={status}
              onSelect={(filter) =>
                documentsFilterSet(filter, DocumentFilters.STATUS)
              }
              selectedFilter={statusFilter}
            />
            <FilterDropdown
              label={t('customer.documents.facets.type')}
              items={type}
              onSelect={(filter) =>
                documentsFilterSet(filter, DocumentFilters.TYPE)
              }
              selectedFilter={typeFilter}
            />
          </div>

          {(categoryFilter || statusFilter || typeFilter) && (
            <div className={`${BASE_CLASS}__tags`}>
              {categoryFilter && (
                <div
                  className={`${BASE_CLASS}__tags-item`}
                  key={categoryFilter.type}
                >
                  <Tag
                    open
                    onOpenChange={() =>
                      documentsFilterSet(undefined, DocumentFilters.CATEGORY)
                    }
                    key={categoryFilter.displayName}
                  >
                    {`${t('customer.documents.facets.category')}: ${
                      categoryFilter.displayName
                    }`}
                    <Tag.Close
                      aria-label={`remove filter ${categoryFilter.displayName}`}
                    />
                  </Tag>
                </div>
              )}

              {statusFilter && (
                <div
                  className={`${BASE_CLASS}__tags-item`}
                  key={statusFilter.type}
                >
                  <Tag
                    open
                    onOpenChange={() =>
                      documentsFilterSet(undefined, DocumentFilters.STATUS)
                    }
                    key={statusFilter.displayName}
                  >
                    {`${t('customer.documents.facets.status')}: ${
                      statusFilter.displayName
                    }`}
                    <Tag.Close
                      aria-label={`remove filter ${statusFilter.displayName}`}
                    />
                  </Tag>
                </div>
              )}

              {typeFilter && (
                <div
                  className={`${BASE_CLASS}__tags-item`}
                  key={typeFilter.type}
                >
                  <Tag
                    open
                    onOpenChange={() =>
                      documentsFilterSet(undefined, DocumentFilters.TYPE)
                    }
                    key={typeFilter.displayName}
                  >
                    {`${t('customer.documents.facets.type')}: ${
                      typeFilter.displayName
                    }`}
                    <Tag.Close
                      aria-label={`remove filter ${typeFilter.displayName}`}
                    />
                  </Tag>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DocumentsSearch;
