import addressFormatter from '@fragaria/address-formatter';
import {
  Appointment,
  ContractType,
  Customer,
  CustomerBusiness,
  CustomerPrivate,
  DocumentCategory,
  DocumentType,
  ExtendedCar,
  GigyaAgent,
  HandoverInput,
  MetadataInput,
  OrderCategoryType,
  OrderDetails,
  OrderItem,
  OrderPaymentType,
  Outlet,
  PartialTestDrive,
  ProductType,
  TestDriveInput,
  TestDriveReturnInput,
} from '@smart/adb-shared';
import { CountryCode, Language } from '@smart/types';
import { AdbLocale } from '@ui/library/helpers/date-locale';

import { getCountryName } from '@utils/helpers/language';
import { MarketCode, marketCodeTimeZoneMap } from '@utils/market/types';
import { UploadType } from '../Documents.config';
import { DocumentSource } from '../documents-context';

export type TestDriveFormValues = {
  drivingLicenseNo: string;
  appointmentTime: string;
  departureMileage: string;
  chargeCableHandover: boolean;
  comment: string;
};

export type TestDriveReturnFormValues = {
  drivingLicenseNo: string;
  appointmentTime: string;
  returnMileage: string;
  chargeCableReturn: boolean;
  comment: string;
};

export type HandoverFormChecklistCustomer = {
  [key in ChecklistValueCustomer]?: boolean;
};

export type HandoverFormChecklistAgent = {
  [key in ChecklistValueAgent]?: boolean;
};

export type HandoverFormValues = {
  leasingContractNo?: string;
  checklist_customer: HandoverFormChecklistCustomer;
  checklist_agent: HandoverFormChecklistAgent;
  comment: string;
};

export type ChecklistValueCustomer =
  | 'checklist_equipment'
  | 'checklist_key'
  | 'checklist_accessories'
  | 'checklist_documents'
  | 'checklist_vehicle_condition'
  | 'checklist_confirmation_insured'
  | 'checklist_functionality'
  | 'checklist_fourteen_days_return'
  | 'checklist_tesla_charger'
  | 'checklist_locking_wheel_nut'
  | 'checklist_purchase_options'
  | 'checklist_awareness';

export type ChecklistValueAgent =
  | 'checklist_loa'
  | 'checklist_insurance'
  | 'checklist_id'
  | 'checklist_certificate_registration'
  | 'checklist_e705_application'
  | 'checklist_coc_document';

/**
 * @interface UploadDocumentForm
 * Upload Document Form values
 */
export interface UploadDocumentForm {
  documentCategory: DocumentCategory;
  documentType: DocumentType;
  appointmentTime: string;
  vin?: string;
  leasingContractNo?: string;
  dateOfSignature?: string;
  demoLicensePlateNumber?: string;
  demoRegistrationDate?: Date;
  demoVin?: string;
}

export type CreateDocumentTypeProps = {
  docSystem: DocumentSource;
  appointments: Appointment[];
  selectedCustomerById: Customer;
  typeOfContract?: string;
  onCompleted?: () => void;
  orderNumber?: string;
  uploadType?: UploadType;
};

export type TestDriveProps = {
  docSystem: DocumentSource;
  formFooter: (disabled?: boolean) => JSX.Element;
  appointments: Appointment[];
  customer: Customer;
  createDocument: (input: TestDriveInput, metadata: MetadataInput) => void;
};

export type TestDriveReturnProps = {
  docSystem: DocumentSource;
  formFooter: (disabled?: boolean) => JSX.Element;
  appointments: Appointment[];
  customer: Customer;
  createDocument: (
    input: TestDriveReturnInput,
    metadata: MetadataInput
  ) => void;
};

export type HandoverContractTypeProps = {
  docSystem: DocumentSource;
  formFooter: (disabled?: boolean) => JSX.Element;
  appointments: Appointment[];
  customer: Customer;
  initialOrderNumber?: string | undefined | null;
  createDocument: (input: HandoverInput, metadata: MetadataInput) => void;
  uploadType?: UploadType;
};

const isLeasingOrder = (order: OrderDetails): boolean =>
  !!order.paymentType &&
  (order.paymentType.toLowerCase() === 'leasing' ||
    order.paymentType.toLowerCase() === 'finance');

const isThirdPartyLeasingOrFinancingOrder = (order: OrderDetails): boolean =>
  order.paymentType === OrderPaymentType.ThirdPartyLeasing ||
  order.paymentType === OrderPaymentType.ThirdPartyFinancing;

export const getCarFeatureByCategoryType = (
  orderItems: OrderItem[],
  categoryType: OrderCategoryType
) =>
  orderItems
    .find((item) => item.productType === 'VEHICLE' && item.features)
    ?.features?.find((feature) => feature.categoryType === categoryType)?.name;

export const getCountry = (
  customer: CustomerPrivate | CustomerBusiness | undefined,
  orderInfo: OrderDetails
): string => {
  if (orderInfo?.customer?.idCountry) return orderInfo.customer.idCountry;
  if (customer?.address?.countryCode) return customer.address.countryCode;
  return '';
};

const mapCustomerData = (customer: Customer, language: Language) => ({
  firstName: customer.firstName ?? '',
  lastName: customer.lastName ?? '',
  postalCode: customer.address?.postalCode ?? '',
  street_house_no: customer.address?.street ?? '',
  city: customer.address?.town ?? '',
  country: customer?.address?.countryCode
    ? getCountryName(language, customer.address?.countryCode as CountryCode)
    : '',
});

export const createHandoverPayload = ({
  formValues,
  customer,
  order,
  outlet,
  agent,
  language,
  timestamp,
  market,
  car,
  translations,
}: {
  formValues: HandoverFormValues;
  customer: Customer;
  order: OrderDetails;
  outlet: Outlet;
  agent: GigyaAgent;
  language: Language;
  timestamp: Date;
  market: MarketCode;
  car: ExtendedCar;
  translations?: Record<string, string>;
}): HandoverInput => {
  const isoTimeStamp = timestamp.toISOString();

  const orderesAccessories = order.items
    .filter((d) => d.productType === ProductType.Accessory && d.productName)
    .map((f) => f.productName ?? '')
    .join(', ');

  const hasExtendedWarranty = order.items.some(
    (item) =>
      item.productType === 'EXTENDED_WARRANTY' &&
      item.features?.some((feature) => feature.code === 'EXTENDED_WARRANTY')
  );

  return {
    typeOfContract: ContractType.HANDOVER_CONTRACT,
    updatedTime: isoTimeStamp,
    comment: formValues.comment,
    customer: {
      ...mapCustomerData(customer, language),
      email: customer.userId ?? '',
      driverDetails: {
        ...mapCustomerData(customer, language),
        ...(isThirdPartyLeasingOrFinancingOrder(order) && {
          ...(order.mainDriverName &&
            order.leasingContractId && {
              mainDriverName: order.mainDriverName,
              leasingContractId: order.leasingContractId,
            }),
        }),
      },
      ...(customer.__typename === 'CustomerBusiness' && {
        companyName: customer.companyName ?? '',
        taxNumber: customer.vatRegistrationNumber ?? '',
        ...(market === 'se' && {
          companyRegistrationNumber: customer.companyRegistrationNumber ?? '',
        }),
      }),
    },
    ...(isLeasingOrder(order) && {
      leasingCompany: {
        country: getCountryName(
          language,
          customer.market?.toLowerCase() as CountryCode
        ),
        name: order.leasingCompanyInfo?.name ?? '',
        taxNumbar: order.leasingCompanyInfo?.taxNumber ?? '',
        street_house_no: order.leasingCompanyInfo?.address ?? '',
        postalCode: addressFormatter
          .format(
            {
              city: order.leasingCompanyInfo?.city,
              postcode: order.leasingCompanyInfo?.postalCode,
            },
            { output: 'string' }
          )
          .trim(),
      },
    }),
    order: {
      paymentType: order.paymentType ?? '',
      customerType: customer?.__typename === 'CustomerPrivate' ? 'B2C' : 'B2B',
    },
    handover: {
      outlet: {
        companyName: outlet.name,
        taxNumber: outlet.vat,
        orgNo: `${outlet.mcsAutoIncrementId}`,
        street_house_no:
          outlet?.address?.street && outlet?.address.buildingNumber
            ? `${outlet?.address.street} ${outlet?.address.buildingNumber}`
            : outlet?.address?.street || '',
        postalCode: outlet.address?.postalCode,
        city: outlet.address?.town,
        nationalIdNo: outlet.vat,
        email: outlet.contact?.email,
        phone: outlet.contact?.phone,
        country: outlet.address?.countryCode
          ? getCountryName(language, outlet.address?.countryCode as CountryCode)
          : '',
      },
      agent: {
        firstName: agent.firstName,
        lastName: agent.lastName,
        email: agent.email,
      },
      locationDetails: {
        street_house_no:
          outlet?.address?.street && outlet?.address.buildingNumber
            ? `${outlet?.address.street} ${outlet?.address.buildingNumber}`
            : outlet?.address?.street || '',
        postalCode: outlet.address?.postalCode ?? '',
        city: outlet.address?.town ?? '',
        country: outlet.address?.countryCode
          ? getCountryName(language, outlet.address?.countryCode as CountryCode)
          : '',
      },
      recipientDetails: mapCustomerData(customer, language),
    },
    carDetails: {
      model: car.modelName ?? '',
      exterior: car.exterior ?? '',
      interior: car.interior ?? '',
      vin: car.carId ?? '',
      line: car.line ?? '',
      licensePlate: car.licensePlateNumber ?? '',
      firstRegistrationDate: car.registrationDate ?? '',
      orderNumber: order.orderId ?? '',
      // eslint-disable-next-line no-nested-ternary
      orderesAccessories: hasExtendedWarranty
        ? orderesAccessories
          ? `${orderesAccessories}, Extended Warranty`
          : 'Extended Warranty'
        : orderesAccessories,
      ...(order?.items?.[0]?.payment?.leasingId && {
        leasingContractNo: order?.items?.[0]?.payment?.leasingId,
      }),
      ...(order.wheelSpecification && {
        wheelSpecification: order.wheelSpecification,
      }),
    },
    checklist: {
      customer: Object.keys(formValues.checklist_customer).map((key) => ({
        checked:
          formValues.checklist_customer[key as ChecklistValueCustomer] ?? false,
        text: translations?.[key] ?? '',
      })),
      agent: Object.keys(formValues.checklist_agent).map((key) => ({
        checked:
          formValues.checklist_agent[key as ChecklistValueAgent] ?? false,
        text: translations?.[key] ?? '',
      })),
    },
  };
};

type TestDriveI = {
  customer: Customer;
  timestamp: Date;
  outlet: Outlet;
  agent: GigyaAgent;
  appointment: Appointment;
  language: Language;
  locale: AdbLocale;
  market: MarketCode;
};

const createPartialTestDrivePayload = (
  {
    customer,
    timestamp,
    outlet,
    agent,
    appointment,
    language,
    locale,
    market,
  }: TestDriveI,
  formValues: { comment: string; drivingLicenseNo: string }
): PartialTestDrive => ({
  updatedTime: timestamp.toISOString(),
  comment: formValues.comment,
  appointmentId: appointment.id,
  customer: {
    drivingLicenseNo: formValues.drivingLicenseNo,
    ...mapCustomerData(customer, language),
    email: customer.userId,
    ...(customer.__typename === 'CustomerBusiness' && {
      companyName: customer.companyName ?? '',
      taxNumber: customer.vatRegistrationNumber ?? '',
      ...(market === 'se' && {
        companyRegistrationNumber: customer.companyRegistrationNumber ?? '',
      }),
    }),
  },
  outlet: {
    companyName: outlet.name,
    taxNumber: outlet.vat,
    street_house_no: outlet.address?.street,
    postalCode: outlet.address?.postalCode,
    city: outlet.address?.town,
    email: outlet.contact?.email,
    phone: outlet.contact?.phone,
    orgNo: `${outlet.mcsAutoIncrementId}`,
    country: outlet.address?.countryCode
      ? getCountryName(language, outlet.address?.countryCode as CountryCode)
      : '',
  },
  agent: {
    firstName: agent.firstName,
    lastName: agent.lastName,
    email: agent.email,
  },
  signature: {
    name: `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`,
    place: outlet.address?.town ?? '',
    Date: timestamp.toLocaleString(locale, {
      timeZone: marketCodeTimeZoneMap[market],
      dateStyle: 'medium',
    }),
  },
  carDetails: {
    model: appointment.car?.model ?? '',
    exterior: appointment.car?.exterior ?? '',
    interior: appointment.car?.interior ?? '',
    vin: appointment.car?.carId ?? '',
    line: appointment.car?.line ?? '',
    licensePlate: appointment.car?.licensePlate ?? '',
  },
});

export const createTestDrivePayload = (
  input: TestDriveI,
  formValues: TestDriveFormValues
): TestDriveInput => {
  const { outlet, appointment, locale, market } = input;
  return {
    typeOfContract: ContractType.TEST_DRIVE_CONTRACT,
    ...createPartialTestDrivePayload(input, formValues),
    testDriveDetails: {
      tripStart: new Date(appointment.start).toLocaleString(locale, {
        timeZone: marketCodeTimeZoneMap[market],
        dateStyle: 'medium',
        timeStyle: 'short',
      }),
      tripEnd: new Date(appointment.end).toLocaleString(locale, {
        timeZone: marketCodeTimeZoneMap[market],
        dateStyle: 'medium',
        timeStyle: 'short',
      }),
      pickUpStation: outlet.address?.town
        ? `${outlet.name}, ${outlet.address?.town}`
        : outlet.name,
      returnStation: outlet.address?.town
        ? `${outlet.name}, ${outlet.address?.town}`
        : outlet.name,
      departureMileage: formValues.departureMileage,
      chargeCableHandover: formValues.chargeCableHandover,
    },
  };
};

export const createTestDriveReturnPayload = (
  input: TestDriveI,
  formValues: TestDriveReturnFormValues
): TestDriveReturnInput => {
  const { outlet, appointment, locale, market } = input;

  return {
    typeOfContract: ContractType.TEST_DRIVE_VEHICLE_RETURN_CONFIRMATION,
    ...createPartialTestDrivePayload(input, formValues),
    testDriveDetails: {
      returnDate: new Date(appointment.end).toLocaleString(locale, {
        timeZone: marketCodeTimeZoneMap[market],
        dateStyle: 'medium',
        timeStyle: 'short',
      }),
      pickUpStation: outlet.address?.town
        ? `${outlet.name}, ${outlet.address?.town}`
        : outlet.name,
      returnStation: outlet.address?.town
        ? `${outlet.name}, ${outlet.address?.town}`
        : outlet.name,
      returnMileage: formValues.returnMileage,
      chargeCableReturn: formValues.chargeCableReturn,
    },
  };
};
