import {
  AssetType,
  OrderPaymentType,
  TaskStep,
  TaskType,
} from '@smart/adb-shared';
import { MarketCode } from '@utils/market/types';
import { TFunction } from 'i18next';
import { ExpertFilter } from 'pages/tasks/config';
import {
  BeautifiedDeliveryStatus,
  CurrentLocation,
  OrderPaymentStatus,
  OrderStatus,
} from '../order/order.model';

/**
 * @interface Facet
 * Facet information
 */
export interface Facet {
  code: string;
  name: string;
  values: FacetValue[];
  visible: boolean;
}

/**
 * @interface FacetValue
 * Facet values information
 */
export interface FacetValue {
  displayName: string;
  initialQueryValue: string;
  lineName: string;
  name: string;
  queryValue: string;
  selected: boolean;
  label?: string;
  code: string;
  market?: MarketCode;
  disabled?: boolean;
  disabledMessage?: string;
}

/**
 * @interface FacetSortByValue
 * FacetSortBy values information
 */
export interface FacetSortByValue {
  displayName: string;
  queryValue: string;
  label?: string;
  disabled?: boolean;
  disabledMessage?: string;
}

/**
 * @const STOCK_SORT_BY_VALUES
 * Stock sorting values
 */
export const STOCK_SORT_BY_VALUES: FacetSortByValue[] = [
  {
    displayName: 'lowest_price',
    queryValue: 'price-asc',
  },
  {
    displayName: 'highest_price',
    queryValue: 'price-desc',
  },
];

/**
 * @const OrderSortByQueryValue
 * Order sort query values
 */
export enum OrderSortByQueryValue {
  DELIVERY_DATE_ASC = 'deliveryDate-asc',
  DELIVERY_DATE_DESC = 'deliveryDate-desc',
  PREFERRED_DELIVERY_DATE_ASC = 'preferredDeliveryDate-asc',
  PREFERRED_DELIVERY_DATE_DESC = 'preferredDeliveryDate-desc',
}

/**
 * @const OrderSortByValues
 * Order sorting values
 */
export const OrderSortByValues: FacetSortByValue[] = [
  {
    displayName: 'earliest_date',
    queryValue: OrderSortByQueryValue.DELIVERY_DATE_ASC,
  },
  {
    displayName: 'latest_date',
    queryValue: OrderSortByQueryValue.DELIVERY_DATE_DESC,
  },
  {
    displayName: 'earliest_preferred_date',
    queryValue: OrderSortByQueryValue.PREFERRED_DELIVERY_DATE_ASC,
  },
  {
    displayName: 'latest_preferred_date',
    queryValue: OrderSortByQueryValue.PREFERRED_DELIVERY_DATE_DESC,
  },
];

/**
 * @const OrderPaymentStatusFilterValues
 * Order payment status filter values
 */
export const OrderPaymentStatusFilterValues: FacetValue[] = [
  {
    displayName: 'not_paid',
    code: 'paymentStatus',
    queryValue: OrderPaymentStatus.NOTPAID,
    initialQueryValue: OrderPaymentStatus.NOTPAID,
    lineName: 'not_paid',
    name: 'payment_status_not_paid',
    selected: false,
    label: 'payment_details.payment_status',
  },
  {
    displayName: 'paid',
    code: 'paymentStatus',
    queryValue: OrderPaymentStatus.PAID,
    initialQueryValue: OrderPaymentStatus.PAID,
    lineName: 'paid',
    name: 'payment_status_paid',
    selected: false,
    label: 'payment_details.payment_status',
  },
];

/**
 * @const OrderTypeFilterValues
 * Order type filter values
 */
export const OrderTypeFilterValues: FacetValue[] = [
  {
    displayName: 'demo_vehicle',
    code: 'orderType',
    queryValue: AssetType.DemoVehicle,
    initialQueryValue: AssetType.DemoVehicle,
    lineName: 'demo_vehicle',
    name: 'order_type_demo_vehicle',
    selected: false,
    label: 'demo_vehicle',
  },
  {
    displayName: 'showroom_vehicle',
    code: 'orderType',
    queryValue: AssetType.ShowroomVehicle,
    initialQueryValue: AssetType.ShowroomVehicle,
    lineName: 'showroom_vehicle',
    name: 'order_type_showroom_vehicle',
    selected: false,
    label: 'showroom_vehicle',
  },
];

/**
 * @const OrderPaymentTypeFilterValues
 * Order payment type filter values
 */
const OrderPaymentTypeFilterValues: FacetValue[] = [
  {
    displayName: 'credit_transfer',
    code: 'paymentType',
    queryValue: OrderPaymentType.Credittransfer,
    initialQueryValue: OrderPaymentType.Credittransfer,
    lineName: 'credit_transfer',
    name: 'payment_type_credit_transfer',
    selected: false,
    label: 'payment_details.payment_type',
  },
  {
    code: 'paymentType',
    displayName: 'leasing',
    queryValue: OrderPaymentType.Leasing,
    initialQueryValue: OrderPaymentType.Leasing,
    lineName: 'leasing',
    name: 'payment_type_leasing',
    selected: false,
    label: 'payment_details.payment_type',
  },
  {
    code: 'paymentType',
    displayName: 'pay_by_invoice',
    queryValue: OrderPaymentType.Paybyinvoice,
    initialQueryValue: OrderPaymentType.Paybyinvoice,
    lineName: 'pay_by_invoice',
    name: 'payment_type_pay_by_invoice',
    selected: false,
    label: 'payment_details.payment_type',
  },
  {
    code: 'paymentType',
    displayName: 'finance',
    queryValue: OrderPaymentType.Finance,
    initialQueryValue: OrderPaymentType.Finance,
    lineName: 'finance',
    name: 'payment_type_finance',
    selected: false,
    label: 'payment_details.payment_type',
  },
  {
    displayName: 'cashbuy',
    code: 'paymentType',
    queryValue: OrderPaymentType.Cashbuy,
    initialQueryValue: OrderPaymentType.Cashbuy,
    lineName: 'cashbuy',
    name: 'payment_type_cash_buy',
    selected: false,
    label: 'payment_details.payment_type',
  },
  {
    displayName: 'third_party_financing',
    code: 'paymentType',
    queryValue: OrderPaymentType.ThirdPartyFinancing,
    initialQueryValue: OrderPaymentType.ThirdPartyFinancing,
    lineName: 'third_party_financing',
    name: 'payment_type_third_party_financing',
    selected: false,
    label: 'payment_details.payment_type',
  },
  {
    displayName: 'third_party_leasing',
    code: 'paymentType',
    queryValue: OrderPaymentType.ThirdPartyLeasing,
    initialQueryValue: OrderPaymentType.ThirdPartyLeasing,
    lineName: 'third_party_leasing',
    name: 'payment_type_third_party_leasing',
    selected: false,
    label: 'payment_details.payment_type',
  },
  {
    displayName: 'loa',
    code: 'paymentType',
    queryValue: OrderPaymentType.Loa,
    initialQueryValue: OrderPaymentType.Loa,
    lineName: 'loa',
    name: 'payment_type_loa',
    selected: false,
    label: 'payment_details.payment_type',
    market: 'fr',
  },
];

export const getOrderPaymentTypeFilterValues = (currentMarket: MarketCode) =>
  OrderPaymentTypeFilterValues.filter(
    ({ market }) => !market || market === currentMarket
  );

/**
 * @const BeautifiedOrderDeliveryStatusFilterValues
 * Beautified order delivery status filter values
 */
export const BeautifiedOrderDeliveryStatusFilterValues: FacetValue[] = [
  {
    displayName: 'confirmed',
    code: 'beautifiedDeliveryStatus',
    initialQueryValue: BeautifiedDeliveryStatus.CONFIRMED,
    queryValue: BeautifiedDeliveryStatus.CONFIRMED,
    lineName: 'confirmed',
    name: 'delivery_status_confirmed',
    selected: false,
    label: 'beautified_delivery_status',
  },
  {
    displayName: 'final_preparation_for_handover',
    code: 'beautifiedDeliveryStatus',
    queryValue: BeautifiedDeliveryStatus.FINAL_PREPARATION_FOR_HANDOVER,
    initialQueryValue: BeautifiedDeliveryStatus.FINAL_PREPARATION_FOR_HANDOVER,
    lineName: 'final_preparation',
    name: 'delivery_status_final_preparation',
    selected: false,
    label: 'beautified_delivery_status',
  },
  {
    displayName: 'handed_over',
    code: 'beautifiedDeliveryStatus',
    queryValue: BeautifiedDeliveryStatus.HANDED_OVER,
    initialQueryValue: BeautifiedDeliveryStatus.HANDED_OVER,
    lineName: 'handed_over',
    name: 'delivery_status_handed_over',
    selected: false,
    label: 'beautified_delivery_status',
  },
  {
    displayName: 'in_delivery',
    code: 'beautifiedDeliveryStatus',
    queryValue: BeautifiedDeliveryStatus.IN_DELIVERY,
    initialQueryValue: BeautifiedDeliveryStatus.IN_DELIVERY,
    lineName: 'in_delivery',
    name: 'delivery_status_in_delivery',
    selected: false,
    label: 'beautified_delivery_status',
  },
];

/**
 * @const CurrentOrderLocationFilterValues
 * Filter values for an order's current location
 */
export const CurrentOrderLocationFilterValues = (
  t: TFunction
): FacetValue[] => [
  {
    code: 'deliveryLocation',
    displayName: t('orders.filter.AGENT'),
    queryValue: CurrentLocation.AGENT,
    initialQueryValue: CurrentLocation.AGENT,
    lineName: 'agent',
    name: 'order_location_agent',
    selected: false,
    label: 'current_location',
  },
  {
    code: 'deliveryLocation',
    displayName: t('orders.filter.CHINA_PORT'),
    queryValue: CurrentLocation.CHINA_PORT,
    initialQueryValue: CurrentLocation.CHINA_PORT,
    lineName: 'china_port',
    name: 'order_location_china_port',
    selected: false,
    label: 'current_location',
  },
  {
    code: 'deliveryLocation',
    displayName: t('orders.filter.IN_TRANSIT_CN_TO_EU'),
    queryValue: CurrentLocation.IN_TRANSIT_CN_TO_EU,
    initialQueryValue: CurrentLocation.IN_TRANSIT_CN_TO_EU,
    lineName: 'in_transit_to_eu',
    name: 'order_location_in_transit_to_eu',
    selected: false,
    label: 'current_location',
  },
  {
    code: 'deliveryLocation',
    displayName: t('orders.filter.IN_TRANSIT_VPC_TO_VLC'),
    queryValue: CurrentLocation.IN_TRANSIT_VPC_TO_VLC,
    initialQueryValue: CurrentLocation.IN_TRANSIT_VPC_TO_VLC,
    lineName: 'in_transit_to_vlc',
    name: 'order_location_in_transit_to_vlc',
    selected: false,
    label: 'current_location',
  },
  {
    code: 'deliveryLocation',
    displayName: t('orders.filter.IN_TRANSIT_VPC_VLC_TO_AGENT'),
    queryValue: CurrentLocation.IN_TRANSIT_VPC_VLC_TO_AGENT,
    initialQueryValue: CurrentLocation.IN_TRANSIT_VPC_VLC_TO_AGENT,
    lineName: 'in_transit_to_agent',
    name: 'order_location_in_transit_to_agent',
    selected: false,
    label: 'current_location',
  },
  {
    code: 'deliveryLocation',
    displayName: t('orders.filter.ZEEBRUGGE_PORT'),
    queryValue: CurrentLocation.ZEEBRUGGE_PORT,
    initialQueryValue: CurrentLocation.ZEEBRUGGE_PORT,
    lineName: 'zeebrugge_port',
    name: 'order_location_zeebrugge_port',
    selected: false,
    label: 'current_location',
  },
];

/**
 * @const CountryVlcFilterValue
 * Country vlc filter values (only for IT, UK, ES markets)
 */
export const CountryVlcFilterValue = (t: TFunction): FacetValue => ({
  code: 'deliveryLocation',
  displayName: t('orders.filter.COUNTRY_VLC'),
  queryValue: CurrentLocation.COUNTRY_VLC,
  initialQueryValue: CurrentLocation.COUNTRY_VLC,
  lineName: 'country_vlc',
  name: 'order_location_country_vlc',
  selected: false,
  label: 'current_location',
});

/**
 * @const OrderStatusFilterValues
 * Order status filter values
 */
export const OrderStatusFilterValues: FacetValue[] = [
  {
    code: 'status',
    displayName: 'cancelled',
    queryValue: OrderStatus.CANCELLED,
    initialQueryValue: OrderStatus.CANCELLED,
    lineName: 'cancelled',
    name: 'order_status_cancelled',
    selected: false,
    label: 'order_status_label',
  },
  {
    code: 'status',
    displayName: 'partial_cancelled',
    queryValue: OrderStatus.PARTIAL_CANCELLED,
    initialQueryValue: OrderStatus.PARTIAL_CANCELLED,
    lineName: 'cancelled',
    name: 'order_status_partial_cancelled',
    selected: false,
    label: 'order_status_label',
  },
  {
    code: 'status',
    displayName: 'cancelling',
    queryValue: OrderStatus.CANCELLING,
    initialQueryValue: OrderStatus.CANCELLING,
    lineName: 'cancelling',
    name: 'order_status_cancelling',
    selected: false,
    label: 'order_status_label',
  },
  {
    code: 'status',
    displayName: 'confirmed',
    queryValue: OrderStatus.CONFIRMED,
    initialQueryValue: OrderStatus.CONFIRMED,
    lineName: 'confirmed',
    name: 'order_status_confirmed',
    selected: false,
    label: 'order_status_label',
  },
  {
    code: 'status',
    displayName: 'created',
    queryValue: OrderStatus.CREATED,
    initialQueryValue: OrderStatus.CREATED,
    lineName: 'created',
    name: 'order_status_created',
    selected: false,
    label: 'order_status_label',
  },
  {
    code: 'status',
    displayName: 'handed_over',
    queryValue: OrderStatus.HANDED_OVER,
    initialQueryValue: OrderStatus.HANDED_OVER,
    lineName: 'handed_over',
    name: 'order_status_handed_over',
    selected: false,
    label: 'order_status_label',
  },
];

/**
 * @const getDocumentActionsFilterValues
 * Document Action Values
 */
export const getDocumentActionsFilterValues = (
  t: TFunction
): FacetSortByValue[] => [
  {
    displayName: t('customer.documents.table.actions.download'),
    queryValue: 'download',
  },
  {
    displayName: t('customer.documents.table.actions.sign'),
    queryValue: 'sign',
    disabledMessage: t(
      'customer.documents.table.actions.sign_disabled_message'
    ),
  },
  {
    displayName: t('customer.documents.table.actions.rename'),
    queryValue: 'rename',
  },
  {
    displayName: t('customer.documents.table.actions.delete'),
    queryValue: 'delete',
  },
];

export const getTaskTypeFilterValues = (market: MarketCode): FacetValue[] => [
  {
    displayName: 'handover',
    code: 'type',
    queryValue: TaskType.Handover,
    initialQueryValue: TaskType.Handover,
    lineName: 'handover',
    name: 'task_type_handover',
    selected: false,
    label: 'task_type.handover',
  },
  {
    displayName: 'test_drive',
    code: 'type',
    queryValue: TaskType.TestDrive,
    initialQueryValue: TaskType.TestDrive,
    lineName: 'test_drive',
    name: 'task_type_test_drive',
    selected: false,
    label: 'task_type.test_drive',
  },
  {
    displayName: 'lead',
    code: 'type',
    queryValue: TaskType.Lead,
    initialQueryValue: TaskType.Lead,
    lineName: 'lead',
    name: 'task_type_lead',
    selected: false,
    label: 'task_type.lead',
  },
  ...(market === 'de'
    ? [
        {
          displayName: 'posident_check',
          code: 'type',
          queryValue: TaskType.PosIdentCheck,
          initialQueryValue: TaskType.PosIdentCheck,
          lineName: 'posident_check',
          name: 'task_type_posident_check',
          selected: false,
          label: 'task_type.posident_check',
        },
      ]
    : []),
];

export const TaskExpertFilterValues: FacetValue[] = [
  {
    displayName: 'you',
    code: 'isAgentAssigned',
    queryValue: ExpertFilter.YOU,
    initialQueryValue: ExpertFilter.YOU,
    lineName: 'assigned',
    name: 'is_agent_assigned_true',
    selected: false,
    label: 'is_agent_assigned_true',
  },
  {
    displayName: 'unassigned',
    code: 'isAgentAssigned',
    queryValue: ExpertFilter.UNASSIGNED,
    initialQueryValue: ExpertFilter.UNASSIGNED,
    lineName: 'unassigned',
    name: 'is_agent_assigned_false',
    selected: false,
    label: 'is_agent_assigned_false',
  },
];

export const TaskStepFilterValues: FacetValue[] = [
  {
    displayName: 'event',
    code: 'step',
    queryValue: TaskStep.Event,
    initialQueryValue: TaskStep.Event,
    lineName: 'event',
    name: 'task_step_event',
    selected: false,
    label: 'task_step.event',
  },
  {
    displayName: 'request',
    code: 'step',
    queryValue: TaskStep.Request,
    initialQueryValue: TaskStep.Request,
    lineName: 'request',
    name: 'task_step_request',
    selected: false,
    label: 'task_step.request',
  },
];

export const TaskModelFilterValues: FacetValue[] = [
  {
    displayName: '#1',
    code: 'model',
    queryValue: '1',
    initialQueryValue: '1',
    lineName: '#1',
    name: 'task_model#1',
    selected: false,
    label: '#1',
  },
  {
    displayName: '#3',
    code: 'model',
    queryValue: '3',
    initialQueryValue: '3',
    lineName: '#3',
    name: 'task_model_#3',
    selected: false,
    label: '#3',
  },
  {
    displayName: '#5',
    code: 'model',
    queryValue: '5',
    initialQueryValue: '5',
    lineName: '#5',
    name: 'task_model#5',
    selected: false,
    label: '#5',
  },
];
