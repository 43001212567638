import { ApolloQueryResult, QueryResult } from '@apollo/client';
import {
  AllTasksInput,
  Exact,
  Task,
  TaskInput,
  TaskStatus,
  TaskType,
} from '@smart/adb-shared';
import { ColumnFiltersState } from '@tanstack/react-table';
import { useLanguageContext } from 'contexts/language-context';
import { client } from 'graphql/client';
import {
  AllTasksDocument,
  AllTasksQuery,
  AllTasksQueryVariables,
  TaskQuery,
  useAllTasksQuery,
  useTaskLazyQuery,
} from 'graphql/queries/tasks.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FilterType } from '../config';
import { filterIdMap } from '../search/search.config';
import {
  formatTaskDate,
  translateTextIfExists,
} from './Table/tasktable.config';

type TaskRow = Task & {
  displayDate: string;
  translatedType: string;
  translatedStatus: string;
};

const createdDate = filterIdMap[FilterType.NEW];

export const allStatusTaskFilter = {
  [FilterType.NEW]: [],
  [FilterType.STATUS]: [],
};

export const newTasksFilter = {
  [FilterType.NEW]: [{ id: createdDate, value: true }],
};

const emptyTaskFilter = {
  [FilterType.EXPERT]: [],
  [FilterType.STEP]: [],
  [FilterType.STATUS]: [],
  [FilterType.TASK]: [],
  [FilterType.MODEL]: [],
};

const statusType = filterIdMap[FilterType.STATUS];

export const openTasksFilter = {
  [FilterType.NEW]: [],
  [FilterType.STATUS]: [
    { id: statusType, value: TaskStatus.Open },
    { id: statusType, value: TaskStatus.InProgress },
    { id: statusType, value: TaskStatus.NotStarted },
    { id: statusType, value: TaskStatus.Started },
    { id: statusType, value: TaskStatus.Requested },
  ],
};

export const completedTasksFilter = {
  [FilterType.NEW]: [],
  [FilterType.STATUS]: [{ id: statusType, value: TaskStatus.Completed }],
};

interface TaskContextValue {
  allTasks: TaskRow[];
  refetchTasks: () => Promise<ApolloQueryResult<AllTasksQuery> | void>;
  loading: boolean;
  getTask: (taskId: string) => Promise<QueryResult<
    TaskQuery,
    Exact<{
      input: TaskInput;
    }>
  > | void>;
  filters: Record<FilterType, ColumnFiltersState>;
  setFilters: React.Dispatch<
    React.SetStateAction<Record<FilterType, ColumnFiltersState>>
  >;
}

const TaskContext = React.createContext<TaskContextValue>({
  allTasks: [],
  loading: false,
  refetchTasks: () => Promise.resolve(),
  getTask: () => Promise.resolve(),
  filters: { ...newTasksFilter, ...emptyTaskFilter },
  setFilters: () => {},
});

const buildFilterInput = (
  filters: Record<FilterType, ColumnFiltersState>
): AllTasksInput => {
  const expertId =
    filters.expert.length > 0 ? (filters.expert[0].value as string) : undefined;
  const taskStatus =
    filters.status.length > 0
      ? (filters.status.map((t) => t.value) as TaskStatus[])
      : undefined;
  const taskType =
    filters.task.length > 0 && filters.task[0].value !== 'new'
      ? (filters.task[0].value as TaskType)
      : undefined;
  const taskModel =
    filters.model.length > 0 ? (filters.model[0].value as string) : undefined;

  return {
    ...(expertId && { expertId }),
    ...(taskStatus && { taskStatus }),
    ...(taskType && { taskType }),
    ...(taskModel && { taskModel }),
  };
};

export const TaskContextProvider = ({ children }: PropsWithChildren) => {
  const outlet = useCurrentOutlet();
  const { locale } = useLanguageContext();
  const { t } = useTranslation();
  const [filters, setFilters] = useState<
    Record<FilterType, ColumnFiltersState>
  >({ ...newTasksFilter, ...emptyTaskFilter });

  const {
    data: outletTasks,
    loading: outletTasksIsLoading,
    refetch: refetchTasks,
  } = useAllTasksQuery({
    variables: {
      input: {
        outletId: outlet?.mcsId,
        ...buildFilterInput(filters),
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: !outlet?.mcsId,
    // Don't set fetch-policy to 'no-cache' because then the logic for getTask wont work.
  });

  const tasks: TaskRow[] = [...(outletTasks?.allTasks.tasks || [])].map(
    (task) => ({
      ...task,
      displayDate: formatTaskDate(task, locale, t),
      translatedType: translateTextIfExists(
        t,
        `task.types.${task.type?.toLowerCase()}`,
        (task.type ?? '') as string
      ),
      title: translateTextIfExists(
        t,
        'task.title',
        (task.title ?? '') as string,
        task.orderNumber
          ? {
              orderId: task.orderNumber ?? '',
            }
          : undefined
      ),
      translatedStatus: task.status
        ? translateTextIfExists(
            t,
            `task.status.${task.status?.toLowerCase()}`,
            (task.status ?? '') as string
          )
        : '-',
      translatedStep: task.step
        ? translateTextIfExists(
            t,
            `task.test_drive.step.${task.step?.toLowerCase()}`,
            (task.step ?? '') as string
          )
        : '-',
    })
  );

  const [getTaskQuery] = useTaskLazyQuery();

  const getTask = useCallback(
    (taskId: string) =>
      getTaskQuery({
        variables: {
          input: {
            outletId: outlet?.mcsId,
            taskId,
          },
        },
        fetchPolicy: 'no-cache',
        onCompleted: ({ task }) => {
          client.cache.updateQuery<AllTasksQuery, AllTasksQueryVariables>(
            {
              query: AllTasksDocument,
              variables: { input: { outletId: outlet?.mcsId } },
            },
            (data) => ({
              allTasks: {
                tasks:
                  data?.allTasks.tasks.map((oldTask) => {
                    if (task.id === oldTask.id) {
                      return task;
                    }
                    return oldTask;
                  }) ?? [],
              },
            })
          );
        },
      }),
    [getTaskQuery, outlet?.mcsId]
  );

  const value = useMemo(
    () => ({
      allTasks: tasks,
      loading: outletTasksIsLoading,
      refetchTasks,
      getTask,
      filters,
      setFilters,
    }),
    [tasks, refetchTasks, getTask, outletTasksIsLoading, filters]
  );

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

export const useTaskContext = () => {
  const context = useContext(TaskContext);
  if (context === undefined) {
    throw new Error('useTaskContext must be used within a TaskContext');
  }
  return context;
};
