import TypeOfContractSelect from '@features/TypeOfContractSelect';
import {
  ContractType,
  HandoverInput,
  MetadataInput,
  TestDriveInput,
  TestDriveReturnInput,
} from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, Spinner, Text } from '@smart/react-components';
import { useMarketContext } from 'contexts/market-context';
import { useNotificationContext } from 'contexts/notification-context';
import { Suspense, lazy, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadType } from '../Documents.config';
import HandoverContract from '../HandoverContract/HandoverContract';
import OutletHandoverContract from '../HandoverContract/OutletHandoverContract';
import TestDriveContract from '../TestDriveContract/TestDriveContract';
import TestDriveContractReturn from '../TestDriveContractReturn/TestDriveContractReturn';
import { CreateDocumentTypeProps } from './CreateDocumentType.config';
import './CreateDocumentType.scss';
import { useMarketSpecificContractOptions } from './contract-helper';
import {
  useHandoverDocumentMutation,
  useTestdriveDocumentMutation,
  useTestdriveReturnDocumentMutation,
} from './queries.generated';

const CustomerInfo = lazy(() => import('./CustomerInfo'));

const BASE_CLASS = 'adb-create-document';

const CreateDocumentType = ({
  appointments,
  selectedCustomerById,
  typeOfContract,
  onCompleted,
  orderNumber,
  uploadType,
  docSystem,
}: CreateDocumentTypeProps) => {
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const contractOptions = useMarketSpecificContractOptions(market, uploadType);
  const { addError, addSuccess } = useNotificationContext();
  const { closeModal } = useModal();
  const [contractType, setContractType] = useState<string>(
    typeOfContract ?? ''
  );

  const [createHandoverMutation, { loading: createHandoverLoading }] =
    useHandoverDocumentMutation();

  const [createTestdriveMutation, { loading: createTestdriveLoading }] =
    useTestdriveDocumentMutation();
  const [
    createTestdriveReturnMutation,
    { loading: createTestdriveReturnLoading },
  ] = useTestdriveReturnDocumentMutation();

  const isLoading =
    createHandoverLoading ||
    createTestdriveLoading ||
    createTestdriveReturnLoading;

  const onSuccess = () => {
    closeModal();
    addSuccess({
      label: t('customer.documents.create.success'),
      message: t('customer.documents.create.save_document_success'),
      onClose: () => {
        if (onCompleted) {
          onCompleted();
        }
      },
    });
  };

  const onError = () => {
    addError({
      label: t('customer.documents.create.error'),
      message: t('customer.documents.create.save_document_error'),
      onClose: () => {
        if (onCompleted) {
          onCompleted();
        }
      },
    });
  };

  const createHandover = (input: HandoverInput, metadata: MetadataInput) => {
    createHandoverMutation({
      variables: {
        input,
        metadata,
      },
      onCompleted: onSuccess,
      onError,
    });
  };

  const createTestdrive = (input: TestDriveInput, metadata: MetadataInput) => {
    createTestdriveMutation({
      variables: {
        input,
        metadata,
      },
      onCompleted: onSuccess,
      onError,
    });
  };

  const createTestdriveReturn = (
    input: TestDriveReturnInput,
    metadata: MetadataInput
  ) => {
    createTestdriveReturnMutation({
      variables: { input, metadata },
      onCompleted: onSuccess,
      onError,
    });
  };

  const getFormFooter = (disabled?: boolean) => (
    <div className={`${BASE_CLASS}__footer`}>
      <Button
        mode={200}
        variant="secondary"
        disabled={false}
        onClick={() => {
          closeModal();
          setContractType('');
        }}
      >
        {t('form_fields.documents.cancel')}
      </Button>
      <Button
        variant="primary"
        type="submit"
        mode={200}
        disabled={disabled}
        loading={isLoading}
      >
        <Button.Spinner />
        {t('form_fields.documents.create')}
      </Button>
    </div>
  );

  const dialogContent = (
    <div className={`${BASE_CLASS}__dialog-content`}>
      <div className={`${BASE_CLASS}__form-input`}>
        <TypeOfContractSelect
          value={
            contractType === '' &&
            contractOptions &&
            contractOptions?.length > 0
              ? contractOptions[0].label
              : contractType
          }
          handleChange={(value) => {
            setContractType(value);
          }}
          options={contractOptions}
          disabled={!!typeOfContract}
        />
      </div>

      {contractType !== '' && (
        <Suspense fallback={<Spinner size="xl" />}>
          <Text variant="cap-100" className={`${BASE_CLASS}__customer-info`}>
            {selectedCustomerById && (
              <CustomerInfo customer={selectedCustomerById} />
            )}
          </Text>
        </Suspense>
      )}

      {contractType === ContractType.HANDOVER_CONTRACT &&
        uploadType !== UploadType.Outlet && (
          <HandoverContract
            docSystem={docSystem}
            formFooter={getFormFooter}
            appointments={appointments}
            customer={selectedCustomerById}
            initialOrderNumber={orderNumber}
            createDocument={createHandover}
          />
        )}
      {contractType === ContractType.HANDOVER_CONTRACT &&
        uploadType === UploadType.Outlet && (
          <OutletHandoverContract
            docSystem={docSystem}
            formFooter={getFormFooter}
            appointments={appointments}
            customer={selectedCustomerById}
            initialOrderNumber={orderNumber}
            createDocument={createHandover}
            uploadType={uploadType}
          />
        )}
      {market !== 'gb' && (
        <>
          {contractType === ContractType.TEST_DRIVE_CONTRACT && (
            <TestDriveContract
              docSystem={docSystem}
              formFooter={getFormFooter}
              appointments={appointments}
              createDocument={createTestdrive}
              customer={selectedCustomerById}
            />
          )}

          {contractType ===
            ContractType.TEST_DRIVE_VEHICLE_RETURN_CONFIRMATION && (
            <TestDriveContractReturn
              docSystem={docSystem}
              formFooter={getFormFooter}
              appointments={appointments}
              createDocument={createTestdriveReturn}
              customer={selectedCustomerById}
            />
          )}
        </>
      )}
      {contractType === '' && (
        <div className={`${BASE_CLASS}__cancel`}>
          <Button
            mode={200}
            variant="secondary"
            onClick={() => {
              closeModal();
              setContractType('');
            }}
          >
            {t('form_fields.documents.cancel')}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <div className={BASE_CLASS}>
      <AdbDialog id="create-document-dialog">
        <AdbDialog.Header>
          <Text variant="hl-100" as="h1">
            {t('form_fields.documents.create_document')}
          </Text>
        </AdbDialog.Header>
        <AdbDialog.Content>{dialogContent}</AdbDialog.Content>
      </AdbDialog>
    </div>
  );
};

export default CreateDocumentType;
