import {
  Appointment,
  AppointmentStatus,
  AppointmentType,
  CarStatus,
  ExtendedCar,
  McsAgent,
} from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import AdbNotification from '@smart/components-adb/molecules/AdbNotification/AdbNotification';
import AdbTooltip from '@smart/components-adb/molecules/AdbTooltip/AdbTooltip';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Flex, Icon, Text } from '@smart/react-components';
import { IconCategories } from '@smart/react-components/dist/components/icon/icon';
import { setNotification } from '@store/calendar/setting';
import { SettingActionType } from '@store/calendar/setting/action';
import { FilterType, type AppointmentsProps } from '@store/calendar/types';
import {
  getCustomFormattedDate,
  setHour,
} from '@ui/library/helpers/date-locale';
import { useAgentContext } from 'contexts/agent-context';
import {
  CalendarContextProvider,
  useCalendarContext,
} from 'contexts/calendar-context';
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useAllAppointmentsQuery } from 'graphql/queries/appointments.generated';
import { useScreenSize } from 'hooks/useScreenSize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, SlotInfo, View, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AppointmentDialog from '../AppointmentDialog/AppointmentDialog';
import CreateAppointmentDialog from '../CreateAppointmentDialog/CreateAppointmentDialog';
import {
  CreateAppointmentInitialData,
  initialData,
} from '../CreateAppointmentDialog/CreateAppointmentDialog.config';
import {
  CALENDAR_VIEWS,
  calendarFormats,
  CalendarViewHeader,
  currentDate,
  dateLocalizer,
} from '../Locales/Locales';
import SideDrawer from '../Sidebar/Mobile/SideDrawer';
import Sidebar from '../Sidebar/Sidebar';
import { BASE_CLASS, BusinessHours, CalendarAppointment } from '../config';
import {
  Color,
  defaultCalendarDateRange,
  DefaultOpeningAppointment,
  getColor,
  Style,
} from './Appointment.config';
import './Appointments.scss';

const getIconFromType = (
  type: string | undefined | null
): IconCategories | null => {
  switch (type) {
    case AppointmentType.Handover:
      return 'smart-dealer';
    case AppointmentType.TestDrive:
      return 'test-drive';
    case AppointmentType.ServiceAppointment:
      return 'vehicle-service-maintenance';
    default:
      return null;
  }
};

const BASE_CLASS_APPOINTMENTS = `${BASE_CLASS}__appointments`;
const EVENT_CLASS = 'rbc-event';

const getColorScheme = (
  event: CalendarAppointment,
  experts: McsAgent[],
  demoCars: ExtendedCar[]
): Color => {
  const indexOfFilter =
    event.filterType === FilterType.Expert
      ? experts.findIndex((expert) => expert.gigyaId === event.expert?.id)
      : demoCars.findIndex((car) => car.carId === event.car?.carId);

  const hasAssignedAgent = Boolean(event.expert?.id);
  return hasAssignedAgent
    ? getColor(indexOfFilter)
    : {
        background:
          'repeating-linear-gradient(135deg,#F4F4F4,#F4F4F4 3px,#fff 3px,#fff 6px)',
        border: '#6B747B',
        dotted: true,
      };
};

const Warnings = ({ event }: { event: CalendarAppointment }) => {
  const { t } = useTranslation();
  return (
    <div className={`${EVENT_CLASS}__warning-wrapper`}>
      {event.car?.status === CarStatus.Inactive && (
        <AdbTooltip
          message={t('feature_calendar.calendar_dialog.deactivated_car_text')}
          className={`${EVENT_CLASS}__tooltip`}
        >
          <Icon icon="warning" className={`${EVENT_CLASS}__icon--warning`} />
        </AdbTooltip>
      )}
      {
        // if start of event is less than current time and event.status is not completed then show the icon
        event.end < new Date() &&
          event.status !== AppointmentStatus.Completed &&
          event.type !== AppointmentType.ServiceAppointment && (
            <AdbTooltip
              message={t(
                'feature_calendar.calendar_dialog.appointment_not_complete'
              )}
              className={`${EVENT_CLASS}__tooltip`}
            >
              <Icon
                icon="info-legal"
                className={`${EVENT_CLASS}__icon--warning`}
              />
            </AdbTooltip>
          )
      }
    </div>
  );
};

const TimeEvent = ({ event }: { event: CalendarAppointment }) => {
  const { t } = useTranslation();
  const { demoCars, experts } = useCalendarContext();
  const icon = getIconFromType(event.type);
  const colorScheme = getColorScheme(event, experts, demoCars);
  const isCancelled = event.status === AppointmentStatus.Cancelled;

  return (
    <Flex
      direction="column"
      padding={{ left: 100, top: 100, bottom: 100, right: 50 }}
      className={`${EVENT_CLASS}__wrapper`}
      style={{
        background: colorScheme.background,
        ...(colorScheme.dotted
          ? { border: `1px dotted ${colorScheme.border}` }
          : { borderLeft: `4px solid ${colorScheme.border}` }),
        ...(isCancelled && {
          border: `1px solid ${colorScheme.border}`,
          background: '#fff',
        }),
      }}
    >
      <Flex justifyContent="space-between">
        <Text variant="cap-400" color="c-mid-contrast">
          {format(event.start, 'HH:mm')}-{format(event.end, 'HH:mm')}
        </Text>
        <Warnings event={event} />
      </Flex>
      <Flex alignItems="center" style={{ gap: 2 }}>
        <Text
          variant="cap-300"
          color="c-high-contrast"
          style={{
            ...(isCancelled && {
              color: colorScheme.border,
              textDecoration: 'line-through',
            }),
          }}
        >
          {t(`feature_calendar.appointment_type.${event.type?.toLowerCase()}`)}
        </Text>
        {icon && (
          <Icon
            icon={icon}
            className={`${EVENT_CLASS}__icon--type`}
            style={{
              ...(isCancelled && {
                color: colorScheme.border,
              }),
            }}
          />
        )}
      </Flex>
      <Text
        variant="cap-300"
        style={{
          ...(isCancelled && {
            color: colorScheme.border,
            textDecoration: 'line-through',
          }),
        }}
      >
        {event.customer?.firstName} {event.customer?.lastName}
      </Text>
    </Flex>
  );
};

const MonthEvent = ({ event }: { event: CalendarAppointment }) => {
  const { t } = useTranslation();
  const { demoCars, experts } = useCalendarContext();

  const icon = getIconFromType(event.type);
  const colorScheme = getColorScheme(event, experts, demoCars);
  const isCancelled = event.status === AppointmentStatus.Cancelled;

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      padding={{ left: 50, top: 50, bottom: 50, right: 50 }}
      className={`${EVENT_CLASS}__wrapper`}
      style={{
        background: colorScheme.background,
        ...(colorScheme.dotted
          ? { border: `1px dotted ${colorScheme.border}` }
          : { borderLeft: `4px solid ${colorScheme.border}` }),
        ...(isCancelled && {
          border: `1px solid ${colorScheme.border}`,
          background: '#fff',
        }),
      }}
    >
      <Flex
        direction="row"
        columnGap={50}
        alignItems="center"
        style={{ overflow: 'hidden' }}
      >
        <Text
          variant="cap-400"
          color="c-mid-contrast"
          style={{
            lineHeight: '100%',
            ...(isCancelled && {
              textDecoration: 'line-through',
            }),
          }}
        >
          {format(event.start, 'HH:mm')}
        </Text>
        <Text
          variant="cap-300"
          color="c-high-contrast"
          style={{
            lineHeight: '100%',
            ...(isCancelled && {
              color: colorScheme.border,
              textDecoration: 'line-through',
            }),
          }}
        >
          {t(`feature_calendar.appointment_type.${event.type?.toLowerCase()}`)}
        </Text>
        {icon && (
          <Icon
            icon={icon}
            className={`${EVENT_CLASS}__icon--type`}
            style={{
              ...(isCancelled && {
                color: colorScheme.border,
              }),
            }}
          />
        )}
      </Flex>
      <Warnings event={event} />
    </Flex>
  );
};

const Appointments = ({
  customerId,
  appointmentType,
  carId,
  preSelectedAppointmentId,
  preSelectedStartTime,
}: AppointmentsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { registerModal } = useModal();
  const { isMobile } = useScreenSize();

  const { agent: loggedInExpert } = useAgentContext();

  const locationState = location.state as DefaultOpeningAppointment;
  const selectedDate =
    locationState?.start ?? preSelectedStartTime ?? currentDate;

  const [currentView, setCurrentView] = useState<View>(Views.WEEK);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<
    string | undefined
  >(locationState?.appointmentId ?? preSelectedAppointmentId ?? undefined);

  const [currentDateState, setCurrentDateState] = useState<string | Date>(
    selectedDate
  );

  const [calendarDateRange, setCalendarDateRange] = useState<[Date, Date]>(
    defaultCalendarDateRange(selectedDate)
  );

  const {
    dispatch,
    locale,
    experts,
    demoCars,
    notification,
    outlet,
    isLoading: loadingCalendarData,
    filter,
  } = useCalendarContext();

  const [demoCarForAppointment, setDemoCarForAppointment] =
    useState<ExtendedCar>();

  const preSelected = !!(
    locationState?.appointmentId || preSelectedAppointmentId
  );

  const [selectedAppointment, setSelectedAppointment] =
    useState<CalendarAppointment>();

  /**
   * manage selected appointment in calendar
   *
   * @param event Appointment
   */
  const onSelectEventHandler = useCallback(
    (event: CalendarAppointment): void => {
      setSelectedAppointment(event);
      if (
        event?.type === AppointmentType.TestDrive ||
        event?.type === AppointmentType.ServiceAppointment
      ) {
        setDemoCarForAppointment(
          demoCars.find((demoCar) => demoCar.vin === event.car?.carId)
        );
      } else {
        setDemoCarForAppointment(event.car as ExtendedCar); // TODO: Casting probably not sane
      }
    },
    [demoCars]
  );

  const getCreateAppointmentFormInitialData = (
    appointmentInitialData: CreateAppointmentInitialData
  ): CreateAppointmentInitialData => ({
    ...appointmentInitialData,
    expertId: loggedInExpert?.id,
  });

  const onRangeChange = (data: Date[] | { start: Date; end: Date }) => {
    if (!Array.isArray(data)) {
      setCalendarDateRange([data.start, data.end]);
    } else {
      setCalendarDateRange([data[0], data[data.length - 1]]);
    }
  };

  const handleSelectedExistingAppointment = (
    appointment: CalendarAppointment
  ) => {
    setSelectedAppointmentId(appointment.id);
    onRangeChange({ start: appointment.start, end: appointment.end });
  };

  const onSelectSlot = (slot: SlotInfo): void => {
    const initialDataFromSlot: CreateAppointmentInitialData = {
      ...initialData({ locale, customerId, appointmentType, carId }),
      startDate: new Date(slot.start).toISOString(),
      endDate: new Date(slot.end).toISOString(),
      expertId: loggedInExpert?.id,
    };

    registerModal(
      <CalendarContextProvider>
        <CreateAppointmentDialog
          initialData={getCreateAppointmentFormInitialData(initialDataFromSlot)}
          calendarDateRange={calendarDateRange}
          setSelectedAppointment={handleSelectedExistingAppointment}
        />
      </CalendarContextProvider>
    );
  };

  const onCreateAppointment = () => {
    registerModal(
      <CalendarContextProvider>
        <CreateAppointmentDialog
          initialData={getCreateAppointmentFormInitialData(
            initialData({ locale })
          )}
          calendarDateRange={calendarDateRange}
          setSelectedAppointment={handleSelectedExistingAppointment}
        />
      </CalendarContextProvider>
    );
  };

  const dayPropGetter = (date: Date): Style => {
    if (date.getDay() === 0) {
      return { style: { backgroundColor: '#e6e6e6' } };
    }

    return { style: { backgroundColor: '#fff' } };
  };

  const { data: appointments, loading } = useAllAppointmentsQuery({
    variables: {
      input: {
        startDateTime: setHour(calendarDateRange[0], 6),
        endDateTime: setHour(calendarDateRange[1], 23),
        outletId: outlet?.bpId ?? '',
      },
    },
    fetchPolicy: 'network-only',
    skip: !outlet?.bpId,
  });

  useEffect(() => {
    dispatch({ type: SettingActionType.SET_LOADING, isLoading: loading });
  }, [dispatch, loading]);

  const updatedAppointments: CalendarAppointment[] = useMemo(
    () =>
      appointments?.allAppointments.appointments.map(
        (appointment: Appointment) => {
          for (const demoCar of demoCars) {
            if (
              demoCar?.vin === appointment.car?.carId &&
              demoCar.status === CarStatus.Inactive
            ) {
              return {
                ...appointment,
                end: new Date(appointment.end),
                start: new Date(appointment.start),
                title: `${getCustomFormattedDate(
                  new Date(appointment.start),
                  'HH:mm',
                  locale
                )} ${t(`${appointment.title}`)}`,
                car: {
                  ...appointment.car,
                  status: demoCar.status,
                },
              };
            }
          }

          return {
            ...appointment,
            end: new Date(appointment.end),
            start: new Date(appointment.start),
            title: `${getCustomFormattedDate(
              new Date(appointment.start),
              'HH:mm',
              locale
            )} ${t(`${appointment.title}`)}`,
          };
        }
      ) ?? [],
    [appointments?.allAppointments.appointments, demoCars, locale, t]
  );

  // below useEffect is used to find the appointment and open the dialog for deep linking functionality
  useEffect(() => {
    if (selectedAppointmentId) {
      const appointment = updatedAppointments?.find(
        (item) => item.id === selectedAppointmentId
      );

      if (appointment) {
        onSelectEventHandler(appointment);
        setCurrentDateState(appointment.start);
      }

      setSelectedAppointment(appointment);
    }
  }, [
    selectedAppointmentId,
    updatedAppointments,
    onSelectEventHandler,
    experts,
    dispatch,
  ]);

  const expertAppointments = updatedAppointments
    .filter(
      (appointment) =>
        filter.some(
          (f) =>
            f.type === FilterType.Expert && f.value === appointment.expert?.id
        ) && appointment.type !== AppointmentType.ServiceAppointment
    )
    .map((app) => {
      const i = filter.findIndex((f) => f.value === app.expert?.id);
      if (i >= 0) {
        return { ...app, filterType: FilterType.Expert };
      }
      return app;
    });

  const carAppointments = updatedAppointments
    .filter((appointment) =>
      filter.some(
        (f) =>
          f.type === FilterType.Car &&
          f.value === appointment.car?.carId &&
          appointment.expert?.id
      )
    )
    .map((app) => {
      const i = filter.findIndex((f) => f.value === app.car?.carId);
      if (i >= 0) {
        return { ...app, filterType: FilterType.Car };
      }
      return app;
    });

  const unassignedAppointments = updatedAppointments.filter(
    (appointment) => !appointment.expert?.id
  );

  // Update calendarDateRange to trigger useAllAppointmentsQuery
  const onNavigate = (date: Date, view: View) => {
    switch (view) {
      case 'month':
      case 'week': {
        setCalendarDateRange([
          startOfWeek(date, { weekStartsOn: 1 }),
          endOfWeek(date, { weekStartsOn: 1 }),
        ]);
        break;
      }
      case 'day': {
        setCalendarDateRange([date, date]);
        break;
      }
      default: {
        setCalendarDateRange([startOfMonth(date), endOfMonth(date)]);
        break;
      }
    }
  };

  const handleNotificationClose = () => setNotification(dispatch, undefined);

  useEffect(() => {
    if (selectedAppointment) {
      registerModal(
        <CalendarContextProvider>
          <AppointmentDialog
            appointment={selectedAppointment}
            demoCar={demoCarForAppointment}
            calendarDateRange={calendarDateRange}
          />
        </CalendarContextProvider>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointment]);

  return (
    <div className={BASE_CLASS_APPOINTMENTS}>
      {notification && (
        <AdbNotification
          onClose={handleNotificationClose}
          label={notification.label}
          text={notification.text}
          variant={notification.variant ?? 'informational'}
          isVisible={!!notification}
        />
      )}
      {isMobile ? (
        <SideDrawer onCreateAppointment={onCreateAppointment} />
      ) : (
        <Sidebar onCreateAppointment={onCreateAppointment} />
      )}
      <div className={`${BASE_CLASS_APPOINTMENTS}__wrapper`}>
        {!preSelected && loadingCalendarData && (
          <LoadingIndicator additionalClasses={`${BASE_CLASS}__loader`} />
        )}
        {preSelected && !selectedAppointment && (
          <LoadingIndicator additionalClasses={`${BASE_CLASS}__loader`} />
        )}
        <Calendar
          localizer={dateLocalizer}
          culture={locale}
          formats={calendarFormats}
          views={{
            month: isMobile ? false : CALENDAR_VIEWS.month,
            week: CALENDAR_VIEWS.week,
            day: CALENDAR_VIEWS.day,
          }}
          defaultDate={currentDateState}
          events={[
            ...carAppointments,
            ...expertAppointments,
            ...unassignedAppointments,
          ]}
          messages={CalendarViewHeader()}
          onSelectSlot={onSelectSlot}
          onSelectEvent={onSelectEventHandler}
          onNavigate={onNavigate}
          onRangeChange={onRangeChange}
          tooltipAccessor={null}
          dayPropGetter={dayPropGetter}
          components={{
            month: { event: MonthEvent },
            week: { event: TimeEvent },
            day: { event: TimeEvent },
          }}
          selectable={!loadingCalendarData}
          // show the week and day view in the calendar only from 6am to 11pm
          min={BusinessHours.min}
          max={BusinessHours.max}
          showMultiDayTimes
          step={currentView === Views.DAY ? 15 : 30}
          onView={(view) => setCurrentView(view)}
          view={currentView}
        />
      </div>
    </div>
  );
};

export default Appointments;
