import { Task, TaskStatus, TaskType } from '@smart/adb-shared';
import {
  ColumnFiltersState,
  TableOptions,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import {
  AdbLocale,
  DateFormats,
  getCustomFormattedDate,
  getLocaleDate,
  getRelativeFormattedDate,
} from '@ui/library/helpers/date-locale';
import { differenceInDays } from 'date-fns';
import i18n, { TFunction } from 'i18next';

export const toggleExpand = 'toggleExpand';
export const taskType = 'taskType';
export const taskExpert = 'taskExpert';
export const taskStatus = 'taskStatus';
export const taskStep = 'taskStep';
export const taskModel = 'taskModel';
export const createdDate = 'createdDate';
const scheduledDate = 'scheduledDate';

const newTaskCutoffDate = new Date('2024-08-14');
export const isNewTask = (task: Task) =>
  new Date(task.createdDate) > newTaskCutoffDate && !task.isAgentAssigned;

export const translateTextIfExists = (
  t: TFunction<'translation', undefined>,
  key: string,
  originalText: string,
  params?: { [key: string]: string }
): string => (i18n.exists(key) && t ? t(key, params) : originalText);

export const formatTaskDate = (
  task: Task,
  locale: AdbLocale,
  t: TFunction<'translation', undefined>
): string => {
  const dateString = task.scheduledDate || task.requestedDate;

  if (
    !dateString ||
    task.status === TaskStatus.Completed ||
    task.status === TaskStatus.Cancelled
  )
    return '-';

  const dateToFormat = new Date(dateString);

  const todayMidnight = new Date();
  todayMidnight.setHours(0, 0, 0, 0);

  const dateToFormatMidnight = new Date(dateString);
  dateToFormatMidnight.setHours(0, 0, 0, 0);
  const dayDelta = differenceInDays(dateToFormatMidnight, todayMidnight);

  if (dayDelta >= 6) return getCustomFormattedDate(dateToFormat, 'P', locale);
  if (dayDelta < 6 && dayDelta > 0) {
    return t('task.handover.days_left', { days: dayDelta });
  }
  if (dayDelta < 0) return t('task.handover.days_overdue', { days: -dayDelta });

  // dayDelta === 0
  return getRelativeFormattedDate(dateToFormat, locale);
};

const getTableConfig = ({
  search,
  setSearch,
  filters,
  tasks,
  t,
  locale,
}: {
  search: string;
  setSearch: (search: string) => void;
  filters: ColumnFiltersState;
  tasks: Task[] | undefined;
  t: TFunction<'translation', undefined>;
  locale: AdbLocale;
}): TableOptions<Task> => ({
  columns: [
    { header: '', id: 'first' },
    { header: '', id: 'id', accessorKey: 'id' },
    {
      header: '',
      id: 'userId',
      accessorKey: 'userId',
      accessorFn: (row) => row.customer.userId ?? '-',
      enableGlobalFilter: true,
    },
    {
      header: `${t('task.type')}`,
      accessorKey: 'translatedType',
      id: taskType,
      enableGlobalFilter: true,
      enableSorting: true,
      filterFn: (row) =>
        filters.some(
          (filter) =>
            filter.id === taskType ||
            (filter.value !== 'new' && isNewTask(row.original))
        ),
    },
    {
      header: `${t('task.step')}`,
      accessorKey: 'translatedStep',
      enableGlobalFilter: true,
      enableSorting: true,
      id: taskStep,
      filterFn: (row) =>
        filters.some(
          (filter) =>
            filter.id === taskStep &&
            (filter.value as string) === row.original.step
        ),
    },
    {
      header: `${t('car.assets.order_number')}`,
      accessorFn: (row) =>
        row.type === TaskType.Handover ? row.orderNumber : '-',
      enableGlobalFilter: true,
      enableSorting: true,
    },
    {
      header: `${t('task.model')}`,
      enableGlobalFilter: true,
      enableSorting: true,
      id: taskModel,
      accessorFn: (row) => row.model || '-',
    },
    {
      header: `${t('car.assets.vin')}`,
      accessorFn: (row) => row.vin ?? '-',
      enableGlobalFilter: true,
      enableSorting: true,
    },
    {
      header: `${t('customer.search.table.contact')}`,
      accessorFn: (row) => `${row.customer.firstName} ${row.customer.lastName}`,
      enableGlobalFilter: true,
      enableSorting: true,
    },
    {
      header: `${t('task.expert')}`,
      accessorFn: (row) => row.agent?.fullName ?? '-',
      enableGlobalFilter: true,
      enableSorting: true,
    },
    {
      header: `${t('general.labels.status')}`,
      accessorKey: 'translatedStatus',
      enableGlobalFilter: true,
      enableSorting: true,
    },
    {
      header: `${t('task.due_date')}`,
      accessorKey: 'displayDate',
      id: scheduledDate,
      enableGlobalFilter: true,
      enableSorting: true,
      sortingFn: (a, b) => {
        if (
          a.original.status === TaskStatus.Completed ||
          a.original.status === TaskStatus.Cancelled
        ) {
          return 1;
        }
        if (
          b.original.status === TaskStatus.Completed ||
          b.original.status === TaskStatus.Cancelled
        ) {
          return -1;
        }
        const firstDate = a.original.scheduledDate || a.original.requestedDate;
        const secondDate = b.original.scheduledDate || b.original.requestedDate;
        if (!firstDate || !secondDate) {
          return 0;
        }
        return new Date(firstDate).valueOf() - new Date(secondDate).valueOf();
      },
    },
    {
      header: `${t('orders.filter.created')}`,
      accessorKey: 'createdDate',
      id: createdDate,
      enableGlobalFilter: true,
      enableSorting: true,
      accessorFn: (row) =>
        row.createdDate
          ? getLocaleDate(row.createdDate, locale, DateFormats.DATE)
          : '-',
      filterFn: (row) =>
        filters.some((filter) => filter.value && isNewTask(row.original)),
      sortingFn: (a, b) =>
        new Date(b.original.createdDate).valueOf() -
        new Date(a.original.createdDate).valueOf(),
    },
    { header: '', id: toggleExpand },
  ],
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  data: tasks ?? [],
  onGlobalFilterChange: setSearch,
  initialState: {
    sorting: [{ desc: false, id: createdDate }],
    columnVisibility: {
      id: false,
      userId: false,
      new: false,
    },
  },
  state: {
    globalFilter: search,
    columnFilters: filters,
  },
});

export default getTableConfig;
