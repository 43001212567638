import {
  AppointmentType,
  ExtendedCar,
  OrderPaymentType,
} from '@smart/adb-shared';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { TypeAhead } from '@smart/components-adb/molecules/TypeAhead/TypeAhead';
import { TypeAheadPropList } from '@smart/components-adb/molecules/TypeAhead/TypeAhead.config';
import { Icon, Text, TextInput } from '@smart/react-components';
import { formatDate } from '@ui/library/helpers/date';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { getLanguage } from '@utils/market/getter';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { Formik } from 'formik';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { useOutletOrderQuery } from 'pages/orders/queries.generated';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type HandoverContractTypeProps } from '../CreateDocumentType/CreateDocumentType.config';
import '../CreateDocumentType/CreateDocumentType.scss';

import { useAvailableOutletCars } from '../CreateDocumentType/contract-helper';
import {
  ContractType,
  createHandoverSubmitHandler,
  getChecklistConfig,
  getHandoverContractTranslations,
  getInitialValues,
  translationsAsString,
} from './HandoverContract.config';
import HandoverContractSchema from './HandoverContractSchema';

const BASE_CLASS = 'adb-create-document';

export const OrderNumberInfoText = ({ text }: { text?: string }) => {
  const { t } = useTranslation();
  return (
    <div className={`${BASE_CLASS}__deactivated-warning`}>
      <Icon
        className={`${BASE_CLASS}__deactivated-warning-icon`}
        icon="warning"
      />
      <Text
        className={`${BASE_CLASS}__deactivated-warning-text`}
        variant="cap-300"
      >
        {text ?? t('feature_calendar.info_messages.visible_order_numbers')}
      </Text>
    </div>
  );
};

const getContractType = (
  paymentType: string | undefined | null
): ContractType | undefined => {
  if (!paymentType) return undefined;

  switch (paymentType) {
    case OrderPaymentType.Leasing:
    case OrderPaymentType.Finance:
    case OrderPaymentType.ThirdPartyLeasing:
    case OrderPaymentType.ThirdPartyFinancing:
      return ContractType.Leasing;

    case OrderPaymentType.Loa:
      return ContractType.Loa;

    default:
      return ContractType.CashBuy;
  }
};

const OutletHandoverContract = ({
  formFooter,
  appointments,
  customer,
  createDocument,
  initialOrderNumber,
  docSystem,
  uploadType,
}: HandoverContractTypeProps) => {
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const { t: defaultT } = useTranslation(undefined, {
    lng: getLanguage(market).locale,
  });

  const { agent } = useAgentContext();
  const { locale, language } = useLanguageContext();
  const outlet = useCurrentOutlet();
  const {
    orderNumbersAsOptions,
    outletCars,
    loading: outletCarsLoading,
  } = useAvailableOutletCars({});

  const tireSpecFlag = useFeatureFlag({
    key: 'use-tirespecs',
    defaultValue: false,
  });

  const [orderNumber, setOrderNumber] = useState<string>(
    initialOrderNumber ?? ''
  );

  const customerCars = outletCars;
  console.log(appointments, 888);
  const handoverAppointments = appointments.filter(
    (item) => item.type === AppointmentType.Handover
  );

  const purchasedCarOptions: TypeAheadPropList[] = orderNumbersAsOptions;

  const { data: selectedOetletOrder, loading: selectedOutletOrderLoading } =
    useOutletOrderQuery({
      variables: {
        input: {
          userId: agent?.email ?? '',
          orderId: orderNumber ?? '',
        },
      },
      skip: !orderNumber,
      onError: (error) => {
        console.error(error);
        enhanceError({
          error,
          label: 'Failed to fetch selected order',
          displayMessage: error.message,
        });
      },
    });

  const selectedCar = customerCars?.find(
    (car: ExtendedCar) => car.orderNumber === orderNumber
  );

  const selectedAppointment = handoverAppointments.find(
    (a) => a.car?.carId === selectedCar?.carId
  );

  const canCreateHandoverContract = Boolean(
    selectedCar &&
      selectedCar.vin &&
      selectedCar.licensePlateNumber &&
      selectedCar.registrationDate &&
      outlet &&
      agent
  );
  console.log(selectedCar, 11);
  console.log(selectedCar?.vin, 22);
  console.log(selectedCar?.licensePlateNumber, 33);
  console.log(selectedCar?.registrationDate, 44);
  console.log(outlet, 55);
  console.log(agent, 66);
  const validationSchema = HandoverContractSchema();

  const type = getContractType(selectedOetletOrder?.outletOrder.paymentType);

  const order = selectedOetletOrder?.outletOrder;

  const checklist = getChecklistConfig({
    market,
    type,
    order,
  });
  const initialValues = getInitialValues(checklist, uploadType);
  const translations = getHandoverContractTranslations(
    defaultT,
    market,
    language,
    checklist,
    order
  );

  const handleHandoverSubmit = createHandoverSubmitHandler({
    createDocument,
    outlet,
    agent,
    selectedAppointment,
    selectedOrder: order,
    selectedCar,
    language,
    customer,
    tireSpecFlag,
    market,
    translations: translationsAsString({ translations }),
    docSystem,
  });

  const onOrderNumberChange = (val: string) => {
    const purchasedCar = customerCars?.find((p) => p.orderNumber === val);
    console.log(purchasedCar, 999);
    if (purchasedCar) {
      setOrderNumber(val);
    } else {
      setOrderNumber('');
    }
  };

  const orderSelect = !outletCarsLoading && (
    <div className={`${BASE_CLASS}__form-input`}>
      <TypeAhead
        customClass={
          orderNumber ? '' : 'adb-create-document__order-number-non-selected'
        }
        list={purchasedCarOptions}
        searchKey={orderNumber}
        name="orderNumber"
        delay={0}
        disableMinCharsToSearch
        onChange={onOrderNumberChange}
        onSelect={(e) => {
          onOrderNumberChange(`${e.id}`);
        }}
        label={t('form_fields.documents.vehicle_order_number')}
        disabled={
          purchasedCarOptions?.length === 0 ||
          typeof initialOrderNumber === 'string' ||
          selectedOutletOrderLoading
        }
        schema={validationSchema}
      />
      {purchasedCarOptions?.length === 0 && <OrderNumberInfoText />}
    </div>
  );

  if (selectedOutletOrderLoading || outletCarsLoading) {
    return (
      <>
        {orderSelect}
        <LoadingIndicator onFullPage />
      </>
    );
  }

  return (
    <>
      {orderSelect}
      {selectedOetletOrder && selectedCar && (
        <Formik
          initialValues={{
            ...initialValues,
          }}
          validationSchema={validationSchema}
          onSubmit={handleHandoverSubmit}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <form autoComplete="on" onSubmit={handleSubmit}>
              <div className={`${BASE_CLASS}__form-input`}>
                <TextInput
                  id="license_plate"
                  value={selectedCar?.licensePlateNumber ?? ''}
                  disabled
                  label={t('car.assets.license_plate')}
                  type="text"
                />
                {!selectedCar?.licensePlateNumber && (
                  <ErrorField
                    errorMsg={t(
                      'form_fields.documents.errors.no_license_plate'
                    )}
                  />
                )}
              </div>
              <div className={`${BASE_CLASS}__form-input`}>
                <TextInput
                  id="registration_date"
                  value={
                    selectedCar?.registrationDate
                      ? formatDate(
                          new Date(selectedCar?.registrationDate),
                          locale
                        )
                      : ''
                  }
                  disabled
                  label={t('car.assets.registration_date')}
                  type="text"
                />
                {!selectedCar?.registrationDate && (
                  <ErrorField
                    errorMsg={t(
                      'form_fields.documents.errors.no_registration_date'
                    )}
                  />
                )}
              </div>

              {formFooter(!canCreateHandoverContract)}
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default OutletHandoverContract;
