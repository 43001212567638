import {
  AppointmentType,
  AssetType,
  Customer,
  Task,
  TaskStatus,
} from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import CalendarModalWrapper from '@smart/components-adb/calendar/CalendarModal';
import { DocumentsContextProvider } from '@smart/components-adb/documents/documents-context';
import CarActivationModal from '@smart/components-adb/molecules/CarActivationModal';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { TableRow } from '@smart/components-adb/molecules/Table/TableComponents/TableComponents';
import { Button, Text } from '@smart/react-components';
import { Row } from '@tanstack/react-table';
import { EventType, IProductEventData } from '@utils/analytics/pushToDataLayer';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { useTracking } from '@utils/hooks/use-tracking';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useCustomerOrderQuery } from 'pages/customer/orders/queries.generated';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeOwnerModal } from '../ChangeOwnerModal';
import { useGetCarQuery } from '../queries.generated';
import { useTaskContext } from '../task-context';
import { getTaskAssignee, getTaskDetails } from '../utils';
import CompleteHandoverModal from './CompleteHandoverModal';
import { DocumentButton } from './DocumentButton';

const BASE_CLASS = 'adb-tasks';

export const HandoverTaskDetails = ({ task: taskRow }: { task: Row<Task> }) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { agent } = useAgentContext();
  const { registerModal, closeModal } = useModal();
  const { getTask, refetchTasks } = useTaskContext();
  const [extendDataLayer] = useTracking<IProductEventData>();

  const task = taskRow.original;
  const { customer, vin } = task;
  const useNewHandoverDocument = useFeatureFlag({
    defaultValue: false,
    key: 'use-new-handover-document',
  });

  const { data, loading: carLoading } = useGetCarQuery({
    variables: {
      input: {
        type: AssetType.SellableVehicle,
        uuid: customer.uuid,
        vin: vin ?? '',
      },
    },
    skip: !customer.uuid || !vin,
    onError: () => {
      closeModal();
    },
  });

  const { data: customerOrderData, loading: orderLoading } =
    useCustomerOrderQuery({
      variables: {
        input: {
          userId: agent?.email ?? '',
          customerId: customer?.userId ?? '',
          orderId: task.orderNumber ?? '',
        },
      },
      fetchPolicy: 'network-only',
      skip: !task.orderNumber || !customer?.userId,
    });

  const car = data?.getCar;
  const order = customerOrderData?.customerOrder;
  const taskDetails = getTaskDetails(task, t, locale, customer, car, order);

  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const onActionCompleted = (refetchTaskAction = 'single') => {
    setIsLoadingAction(true);
    closeModal();

    if (refetchTaskAction === 'single')
      getTask(task.id).finally(() => setIsLoadingAction(false));
    if (refetchTaskAction === 'all') refetchTasks();
  };

  const isLoading = carLoading || orderLoading;

  return !isLoading ? (
    <TableRow gridColumns="">
      <td className={`${BASE_CLASS}__task-inner`}>
        <div className={`${BASE_CLASS}__task-details`}>
          <Text variant="lbl-200" as="p">
            {t('task.handover.title', { orderId: order?.orderId })}
          </Text>
          <Text variant="p-100" as="p">
            {t('task.handover.instruction')}
          </Text>
          <div>
            <ul className={`${BASE_CLASS}__task-details-list`}>
              {Object.keys(taskDetails).map((key) => (
                <li key={key}>
                  <Text variant="lbl-100">{t(key)}</Text>
                  {typeof taskDetails[key] === 'string' ? (
                    <Text variant="p-100">{taskDetails[key] as string}</Text>
                  ) : (
                    <>{(taskDetails[key] as () => JSX.Element)()}</>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={`${BASE_CLASS}__task-actions`}>
          <Button
            variant="primary"
            loading={isLoadingAction}
            onClick={() => {
              registerModal(
                <ChangeOwnerModal
                  task={taskRow}
                  onComplete={onActionCompleted}
                />
              );
            }}
          >
            {getTaskAssignee({ task, t })}
            {task.isAgentAssigned ? (
              <Button.Icon
                icon="successful-checkmark"
                aria-label="successful-checkmark"
              />
            ) : (
              <Button.Icon icon="arrow-right" aria-label="arrow-right" />
            )}
          </Button>
          <Button
            variant="primary"
            disabled={!task.isAgentAssigned}
            onClick={() => {
              extendDataLayer(
                {
                  type: 'feature',
                  region: 'tasks',
                  name: 'handover.appointment.create',
                  product: [
                    {
                      id: task?.vehicle?.id || '',
                      name: task?.vehicle?.productName || '',
                      model: task?.vehicle?.model || '',
                      color: [
                        {
                          interior: task?.vehicle?.interior || '',
                          exterior: task?.vehicle?.exterior || '',
                        },
                      ],
                    },
                  ],
                },
                EventType.CLICK
              );
              registerModal(
                <CalendarModalWrapper
                  customerId={customer.uuid ?? ''}
                  appointmentType={AppointmentType.Handover}
                  carId={vin}
                  preSelectedAppointmentId={task.appointment?.id ?? undefined}
                  preSelectedStartTime={task.scheduledDate ?? undefined}
                />
              );
            }}
          >
            {t('task.handover.create_appointment')}
            {task.appointment?.id && (
              <Button.Icon
                icon="successful-checkmark"
                aria-label="successful-checkmark"
              />
            )}
          </Button>
          <Button
            variant="primary"
            disabled={!car || !task.isAgentAssigned}
            onClick={() => {
              if (car) {
                extendDataLayer(
                  {
                    type: 'feature',
                    region: 'tasks',
                    name: 'handover.car_registration',
                    product: [
                      {
                        id: task?.vehicle?.id || '',
                        name: task?.vehicle?.productName || '',
                        model: task?.vehicle?.model || '',
                        color: [
                          {
                            interior: task?.vehicle?.interior || '',
                            exterior: task?.vehicle?.exterior || '',
                          },
                        ],
                      },
                    ],
                  },
                  EventType.CLICK
                );
                registerModal(
                  <CarActivationModal car={car} customer={customer} />
                );
              }
            }}
          >
            {t('task.handover.car_registration')}
            {car?.isInfleeted && (
              <Button.Icon
                icon="successful-checkmark"
                aria-label="successful-checkmark"
              />
            )}
          </Button>
          <DocumentsContextProvider customer={customer as Customer}>
            <DocumentButton
              task={task}
              orderNumber={task.orderNumber ?? ''}
              customer={customer}
            />
          </DocumentsContextProvider>

          {!useNewHandoverDocument && (
            <Button
              variant="primary"
              disabled={!task.appointment?.id || !task.isAgentAssigned}
              onClick={() => {
                extendDataLayer(
                  {
                    type: 'feature',
                    region: 'tasks',
                    name: 'handover.complete_appointment',
                    product: [
                      {
                        id: task?.vehicle?.id || '',
                        name: task?.vehicle?.productName || '',
                        model: task?.vehicle?.model || '',
                        color: [
                          {
                            interior: task?.vehicle?.interior || '',
                            exterior: task?.vehicle?.exterior || '',
                          },
                        ],
                      },
                    ],
                  },
                  EventType.CLICK
                );
                registerModal(
                  <CompleteHandoverModal task={task} closeModal={closeModal} />
                );
              }}
            >
              {t('task.handover.complete_appointment')}
              {task.status === TaskStatus.Completed && (
                <Button.Icon
                  icon="successful-checkmark"
                  aria-label="successful-checkmark"
                />
              )}
            </Button>
          )}
        </div>
      </td>
    </TableRow>
  ) : (
    <TableRow gridColumns="">
      <td
        className={`${BASE_CLASS}__task-inner`}
        aria-label={t('general.labels.loading_msg')}
      >
        <LoadingIndicator size="xl" />
      </td>
    </TableRow>
  );
};
